<template>
    <transition>
        <div v-show="visible" class="fixed inset-0 overflow-y-auto" style="z-index: 1001;">
            <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
                <div class="fixed inset-0 transition-opacity" aria-hidden="true">
                    <div class="absolute inset-0 bg-gray-900 opacity-75" />
                </div>
                <div
                    class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden
                    shadow-xl transform transition-all w-[1000px] my-8"
                    role="dialog"
                    aria-modal="true"
                    aria-labelledby="modal-headline"
                >
                    <div class="flex w-full justify-between py-4 px-5 mb-4 bg-gray-100 border-b border-gray-200">
                        <slot name="header" />
                        <button type="button" aria-label="Close" @click="$emit('close')">
                            <svg class="w-6 h-6 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                    <div class="px-10">
                        <slot name="body" />
                    </div>
                    <div class="flex w-full justify-end py-4 px-5 mt-4 bg-gray-100 border-t border-gray-200">
                        <slot name="footer" />
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    props: {
        visible: {
            type:    Boolean,
            default: true,
        },
    },

    watch: {
        visible(value) {
            if (value) {
                window.addEventListener('keydown', this.keyPressed);
                return;
            }
            window.removeEventListener('keydown', this.keyPressed);
        },
    },

    methods: {
        keyPressed(e) {
            if (e.key === 'Escape') {
                e.preventDefault();
                this.$emit('close');
            }
        },
    },
};
</script>
