<template>
    <ModalContainer :visible="modalVisible" class="dialog-add-event" @close="closeModal">
        <template #header>
            <h1>
                {{ $t('calendar.add_event') }}
            </h1>
        </template>
        <template #body>
            <el-form label-position="top">
                <div class="px-16">
                    <ClientsAddressesDropdown ref="clientsAddressesDropdown" @change="selectedAddress = $event.uuid" />
                </div>

                <div class="px-16 grid grid-cols-12 gap-4">
                    <div class="col-span-6">
                        <DatePickers
                            :recursive="recursive"
                            :modalVisible="modalVisible"
                            @startDateChange="startDate = $event"
                            @endDateChange="endDate = $event"
                        />
                    </div>
                    <div class="col-span-4">
                        <TimePickers
                            ref="timePickersForm"
                            :startDateProp="startDateProp"
                            :modalVisible="modalVisible"
                            @startTimeChanged="startTime = $event"
                            @endTimeChanged="endTime = $event"
                        />
                    </div>
                    <div class="col-span-2">
                        <ColorSelect @change="selectedColor = $event" />
                    </div>
                </div>

                <div class="grid grid-cols-2 gap-4 px-16">
                    <EmployeesSelect :modalVisible="modalVisible" @change="selectedEmployees = $event" />
                    <ServicesSelect :modalVisible="modalVisible" @change="selectedServices = $event" />
                </div>

                <div class="px-16">
                    <Notes :modalVisible="modalVisible" @change="note = $event" />
                </div>

                <hr class="mb-6 mt-5 mx-16">

                <RecurringSection
                    :modalVisible="modalVisible"
                    @recursiveChange="recursive = $event"
                    @repeatWeekDaysChange="repeatWeekDays = $event"
                    @repeatEveryWeekChange="repeatEveryWeek = $event"
                />
            </el-form>
        </template>
        <template #footer>
            <div class="flex w-full justify-end">
                <el-button @click="closeModal">
                    {{ $t('common.cancel') }}
                </el-button>
                <el-button :loading="$wait.is('adding')" type="primary" :disabled="!userCan('create calendar events')" @click="addEvent">
                    {{ $t('common.add') }}
                </el-button>
            </div>
        </template>
    </ModalContainer>
</template>
<script>
import ModalContainer from '@/components/modals/ModalContainer.vue';

export default {
    components: {
        ModalContainer,
        ClientsAddressesDropdown: () => import(/* webpackChunkName: "ClientsAddressesDropdown" */ '@/components/ClientsAddressesDropdown'),
        DatePickers:              () => import(/* webpackChunkName: "ModalAddEvent/DatePickers" */ './components/DatePickers'),
        TimePickers:              () => import(/* webpackChunkName: "ModalAddEvent/TimePickers" */ './components/TimePickers'),
        ColorSelect:              () => import(/* webpackChunkName: "ModalAddEvent/ColorSelect" */ './components/ColorSelect'),
        Notes:                    () => import(/* webpackChunkName: "ModalAddEvent/Notes" */ './components/Notes'),
        EmployeesSelect:          () => import(/* webpackChunkName: "ModalAddEvent/EmployeesSelect" */ './components/EmployeesSelect'),
        ServicesSelect:           () => import(/* webpackChunkName: "ModalAddEvent/ServicesSelect" */ './components/ServicesSelect'),
        RecurringSection:         () => import(/* webpackChunkName: "ModalAddEvent/RecurringSection" */ './components/RecurringSection'),
    },

    props: {
        visible: {
            type:    Boolean,
            default: false,
        },
        preSelectedEmployeeProp: {
            type:    String,
            default: null,
        },
    },

    data() {
        return {
            recursive:         1,
            startDate:         this.$dayjs(),
            endDate:           null,
            selectedColor:     '#20a0ff',
            selectedDate:      this.$dayjs(),
            startDateProp:     this.$dayjs(),
            // startDateProp:     this.startDateSelected,
            selectedAddress:   null,
            startTime:         '07:00',
            endTime:           '08:00',
            note:              null,
            selectedServices:  [],
            selectedEmployees: [],
            repeatEveryWeek:   1,
            repeatWeekDays:    [],
        };
    },

    computed: {
        eventsConfirmation() {
            return this.$store.state.eventConfirmation;
        },
        modalVisible() {
            return this.$store.state.calendar.modalAddNewVisible;
        },
    },

    watch: {
        modalVisible() {
            this.selectedServices = [];
            this.selectedEmployees = [];
            this.$refs.clientsAddressesDropdown.resetSelection();
        },
        recursive() {
            if (this.recursive === 0) {
                this.endDate = null;
            }
        },
    },

    methods: {
        async addEvent() {
            await new Promise(resolve => this.$refs.clientsAddressesDropdown.$refs.formData.validate(valid => valid && resolve()));
            await new Promise(resolve => this.$refs.timePickersForm.$refs.formData.validate(valid => valid && resolve()));

            this.$wait.start('adding');

            const startTime = this.$dayjs(`${this.$dayjs(this.startDate).format('DD.MM.YYYY')} ${this.startTime}`, 'DD.MM.YYYY HH:mm');
            const endTime = this.$dayjs(`${this.$dayjs(this.startDate).format('DD.MM.YYYY')} ${this.endTime}`, 'DD.MM.YYYY HH:mm');

            const duration = endTime.diff(startTime, 'minute');
            const endDate = this.endDate ? this.$dayjs(this.endDate) : this.$dayjs().add(2, 'year');
            const infinite = this.endDate ? 0 : 1;

            try {
                await axios.post(`${process.env.VUE_APP_CORE_API_URL}/services/calendar`, {
                    infinite,
                    start_event_at:      startTime.unix(),
                    color:               this.selectedColor,
                    duration,
                    note:                this.note,
                    employees:           this.selectedEmployees,
                    services:            this.selectedServices,
                    recurring:           this.recursive,
                    week_days:           this.repeatWeekDays,
                    repeat_every_week:   this.repeatEveryWeek,
                    end_recursive_at:    endDate.unix(),
                    events_confirmation: this.eventsConfirmation,
                    address_uuid:        this.selectedAddress,
                });
                this.$notify.success({ title: this.$t('common.success') });
                this.$store.commit('calendar/refreshCalendarEvents');
                this.$wait.end('adding');
                this.closeModal();
            } catch (error) {
                this.$wait.end('adding');
            }
        },

        closeModal() {
            this.$store.commit('calendar/closeModalAddNewEvent');
        },
    },
};
</script>
