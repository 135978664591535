<template>
    <div ref="scrollTarget" class="flex relative">
        <ModalAddEventNew
            :preSelectedEmployeeProp="preSelectedEmployee"
        />

        <ModalEditEventNew />

        <ModalDragDropChangeDate />

        <ModalConfirmCalendar
            v-if="modalConfirmCalendarVisible"
            :modalConfirmCalendar="modalConfirmCalendar"
            @close="modalConfirmCalendar = false"
            @closed="modalConfirmCalendarVisible = false"
        />

        <ModalSmartCalendar
            v-if="modalSmartCalendarVisible"
            :modalSmartCalendar="modalSmartCalendar"
            @close="modalSmartCalendar = false"
            @closed="modalSmartCalendarVisible = false"
        />

        <div class="filter-button" @click="filtersToggle">
            <fa-icon :icon="['fas', 'filter']" fixed-width />
        </div>

        <div v-show="filtersVisible" class="w-64 mr-3 relative">
            <affix class="w-64" relative-element-selector="#example-content" :offset="{ top: 15, bottom: 25 }">
                <Filters
                    @calendarViewChanged="changeCalendarView"
                    @confirmCalendarButtonClicked="showConfirmCalendarModal"
                    @showModalSmartSuggestions="showModalSmartSuggestions"
                />
            </affix>
        </div>

        <div id="example-content" class="flex-1 relative">
            <template v-if="calendarView === 'month'">
                <MonthNewCalendar />
            </template>

            <template v-if="calendarView === 'week_per_employee_plus'">
                <WeekPerEmployeePlusCalendar ref="weekPerEmployeePlusCalendar" />
            </template>

            <template v-if="calendarView === 'timeline'">
                <TimeLineCalendar ref="timelineCalendar" />
            </template>
        </div>
    </div>
</template>
<script>
import Cookies from 'js-cookie';
import Vue from 'vue';
import Affix from 'vue-affix';
import ModalAddEventNew from './modals/ModalAddEventNew.vue';
import ModalEditEventNew from './modals/ModalEditEventNew.vue';
import ModalDragDropChangeDate from './modals/ModalDragDropChangeDate.vue';

Vue.use(Affix);

export default {
    components: {
        MonthNewCalendar:            () => import(/* webpackChunkName: "Calendar/MonthNewCalendar" */ './views/MonthBasicNew/MonthNewCalendar'),
        WeekPerEmployeePlusCalendar:
            () => import(/* webpackChunkName: "Calendar/WeekPerEmployeePlusCalendar" */ './views/WeekPerEmployeePlus/WeekPerEmployeePlusCalendar'),
        TimeLineCalendar:     () => import(/* webpackChunkName: "Calendar/TimeLineCalendar" */ './views/TimeLine/TimeLineCalendar'),
        Filters:              () => import(/* webpackChunkName: "Calendar/Filters" */ './components/Filters'),
        ModalConfirmCalendar: () => import(/* webpackChunkName: "Calendar/ModalConfirmCalendar" */ './modals/ModalConfirmCalendar'),
        ModalSmartCalendar:   () => import(/* webpackChunkName: "Calendar/ModalSmartCalendar" */ './modals/ModalSmartCalendar'),
        ModalAddEventNew,
        ModalEditEventNew,
        ModalDragDropChangeDate,
    },

    data() {
        return {
            calendarView:                Cookies.get('calendar_view') !== undefined ? Cookies.get('calendar_view') : 'month',
            filtersVisible:              localStorage.getItem('filtersVisible') ? JSON.parse(localStorage.getItem('filtersVisible')) : false,
            modalSmartCalendarVisible:   false,
            modalSmartCalendar:          false,
            modalConfirmCalendarVisible: false,
            modalConfirmCalendar:        false,
            modalStartDate:              null,
            preSelectedEmployee:         null,
        };
    },

    created() {
        this.$store.commit('setPageTitle', this.$t('calendar.calendar'));
        this.$store.commit('setActiveMenuItem', '2');
    },

    methods: {
        changeCalendarView(view) {
            this.calendarView = view;
        },

        async showConfirmCalendarModal() {
            this.modalConfirmCalendarVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalConfirmCalendar = true;
        },

        async showModalSmartSuggestions() {
            this.modalSmartCalendarVisible = true;
            await new Promise(resolve => setTimeout(resolve, 50));
            this.modalSmartCalendar = true;
        },

        filtersToggle() {
            localStorage.setItem('filtersVisible', !this.filtersVisible);
            this.filtersVisible = !this.filtersVisible;
        },
    },
};
</script>
<style>
.filter-button {
    position: absolute;
    background: linear-gradient(135deg, #81FBB8, #28C76F);
    color: #fff;
    top: -6px;
    left: -3px;
    z-index: 10;
    width: 50px;
    height: 50px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 19px;
    box-shadow: 2px 2px 3px 1px #0000002e;
    cursor: pointer;
    z-index: 1000;
}
</style>
