<template>
    <ModalContainer :visible="modalVisible" class="dialog-edit-event" @close="closeModal">
        <template #header>
            <h1>
                {{ $t('calendar.edit_event') }} -
                <router-link :to="`/clients/${clientUuid}/dashboard`" class="ml-1 hover:text-red-500">
                    {{ modalTitle }}
                </router-link>
            </h1>
        </template>
        <template #body>
            <el-tabs v-model="selectedTab" class="px-8 mb-4 mt-7" stretch>
                <el-tab-pane name="general">
                    <span slot="label" class="font-normal">
                        <fa-icon :icon="['fas', 'info-circle']" fixed-width class="mr-1" /> {{ $t('calendar.event_information') }}
                    </span>
                    <GeneralInformation
                        :modalVisible="modalVisible"
                        :eventUuid="eventUuid"
                        @updated="updateModalHeader"
                        @refresh="$emit('refresh')"
                    />
                </el-tab-pane>
                <!-- <el-tab-pane lazy name="client_profile">
                    <span slot="label" class="font-normal">
                        <fa-icon :icon="['fas', 'user']" fixed-width class="mr-1" /> {{ $t('calendar.client_profile') }}
                    </span>
                    <ClientProfile
                        v-if="selectedTab === 'client_profile'"
                        :eventUuid="eventUuid"
                    />
                </el-tab-pane> -->
                <el-tab-pane lazy name="future_events">
                    <span slot="label" class="font-normal">
                        <fa-icon :icon="['fas', 'calendar-alt']" fixed-width class="mr-1" />  {{ $t('calendar.future_services') }}
                    </span>
                    <FutureEvents v-if="selectedTab === 'future_events'" :eventUuid="eventUuid" />
                </el-tab-pane>
                <!-- <el-tab-pane lazy disabled>
                    <span slot="label" class="font-normal">
                        <fa-icon :icon="['fas', 'comment-dots']" fixed-width class="mr-1" />  {{ $t('calendar.communication') }}
                    </span>
                </el-tab-pane> -->
            </el-tabs>
        </template>
        <template #footer>
            <div class="flex w-full justify-between">
                <span v-if="cancelled == 1" class="label-cancelled">{{ $t('calendar.cancelled') }}</span>
            </div>
        </template>
    </ModalContainer>
</template>
<script>
import ModalContainer from '@/components/modals/ModalContainer.vue';

export default {
    components: {
        ModalContainer,
        GeneralInformation: () => import(/* webpackChunkName: "ModalEditEvent/GeneralInformation" */ './components/modal_edit/GeneralInformation'),
        FutureEvents:       () => import(/* webpackChunkName: "ModalEditEvent/FutureEvents" */ './components/modal_edit/FutureEvents'),
        ClientProfile:      () => import(/* webpackChunkName: "ModalEditEvent/ClientProfile" */ './components/modal_edit/ClientProfile'),
    },

    data() {
        return {
            cancelled:   false,
            clientUuid:  null,
            modalTitle:  null,
            selectedTab: 'general',
        };
    },

    computed: {
        modalVisible() {
            return this.$store.state.calendar.modalEditNewVisible;
        },
        eventUuid() {
            return this.$store.state.calendar.eventToEditUuid;
        },
    },

    watch: {
        modalVisible() {
            this.selectedTab = 'general';
        },
    },

    methods: {
        updateModalHeader(event) {
            this.clientUuid = event.clientUuid;
            this.modalTitle = event.modalTitle;
        },

        closeModal() {
            this.$store.commit('calendar/closeModalEditNewEvent');
        },
    },
};
</script>
<style>
.label-cancelled {
    position: absolute;
    top: 6px;
    width: 100%;
    left: 0;
    text-align: center;
    color: #ff4a4a;
}
</style>
