<template>
    <ModalContainer :visible="modalVisible" class="dialog-edit-event" @close="closeModal">
        <template #header>
            {{ $t('calendar.change_date_confirmation') }}
        </template>
        <template #body>
            <div class="flex justify-around items-center py-20 px-20">
                <div>
                    <p class="text-center">
                        {{ $t('calendar.old_date') }}:
                    </p>
                    <p class="text-4xl font-semibold text-green-500">
                        {{ oldDate }}
                    </p>
                </div>
                <div class="pt-8">
                    <fa-icon :icon="['fas', 'arrow-circle-right']" fixed-width class="text-6xl text-green-300" />
                </div>
                <div>
                    <p class="text-center">
                        {{ $t('calendar.new_date') }}:
                    </p>
                    <p class="text-4xl font-semibold text-green-500">
                        {{ newDate }}
                    </p>
                </div>
            </div>
        </template>
        <template #footer>
            <div class="flex w-full justify-end">
                <el-button type="" @click="closeModal">
                    {{ $t('common.cancel') }}
                </el-button>
                <el-button type="primary" :loading="$wait.is('loading')" :disabled="!userCan('update calendar events')" @click="changeDate">
                    {{ $t('common.confirm') }}
                </el-button>
            </div>
        </template>
    </ModalContainer>
</template>
<script>
import ModalContainer from '@/components/modals/ModalContainer.vue';

export default {
    components: {
        ModalContainer,
    },

    computed: {
        modalVisible() {
            return this.$store.state.calendar.modalDragDropChangeDateVisible;
        },
        eventUuid() {
            return this.$store.state.calendar.eventUuidToChangeDate;
        },
        oldDate() {
            return this.$store.state.calendar.dragDropOldDate;
        },
        newDate() {
            return this.$store.state.calendar.dragDropNewDate;
        },
    },

    methods: {
        async changeDate() {
            const oldDate = this.$dayjs(this.oldDate, 'DD.MM.YYYY');
            const newDate = this.$dayjs(this.newDate, 'DD.MM.YYYY');
            const delta = newDate.diff(oldDate, 'day');
            this.$wait.start('loading');
            await axios.patch(`${process.env.VUE_APP_CORE_API_URL}/services/calendar/${this.eventUuid}/update_date`, {
                delta,
            });
            this.$wait.end('loading');
            this.$store.commit('calendar/refreshCalendarEvents');
            this.closeModal();
        },

        closeModal() {
            this.$store.commit('calendar/closeModalDragDropChangeDateVisible');
        },
    },
};
</script>
